.gallery-container {
    max-width: 900px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .gallery-header {
    text-align: center;
    color: #333;
  }
  
  .form-container {
    margin-bottom: 30px;
  }
  
  .input-field {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .input-file {
    margin: 10px 0;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .posts-container {
    margin-top: 30px;
  }
  
  .post-item {
    list-style-type: none;
    margin: 10px 0;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .view-button, .delete-button {
    margin-right: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .view-button {
    background-color: #28a745;
    color: #fff;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: #fff;
  }
  
  .post-details {
    margin-top: 30px;
  }
  
  .post-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
 .carding {
  height: 350px;
  display: flex;
  flex-direction: column;
}

.media-containering {
  height: 250px; 
  overflow: hidden; 
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .media-containering img, .media-containering video {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  object-fit: contain;
} */

/* .card-body {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}  */
  /* Gallery.css */

/* Common styles for the gallery container */
.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Unique styles for different roles */
.user-card, .public-card, .admin-card {
  /* Add any card-specific styles here */
}

.user-media-container, .public-media-container, .admin-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.card-img-top, .card-img {
  /* object-fit: cover; */
  height: 300px; /* Set a fixed height for the images */
  width: 100%;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  background: white;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  font-size: 1.5em;
  cursor: pointer;
}

.modal-body {
  margin-top: 10px;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.sidebar-open {
  margin-left: 250px; /* Adjust as needed */
}

/* Media query for responsive design */
@media (max-width: 768px) {
  .card-img {
    height: auto;
  }
}
