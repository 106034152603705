.container.open{
    margin-left: 200px;
    width: 80%;
}

@media screen and (max-width:1000px) {
    .container.open{
        margin-left: 280px;
        width: 65%;
    }
}
@media screen and (max-width:768px) {
    .container.open{
        margin-left: 60px; 
        width: 86%;
    }
}
@media screen and (max-width:425px) {
    .container.open{
        margin-left: 40px; 
        width: 86%;
    }
}