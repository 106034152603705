/* Courses.css */

/* Container for courses list */
.courses-container {
    padding: 20px;
  }
  
  /* Title of the courses section */
  .courses-title {
    font-size: 2em;
  }
  
  /* Container for individual course cards */
  .courses-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  /* Individual course card */
  .course-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Image inside course card */
  .course-image {
    /* width: 100%; */
    height: 200px;
    /* object-fit: cover; */
  }
  
  /* Course name and price styling */
  .course-name {
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  .course-price {
    font-size: 1.2em;
    color: #555;
  }
  
  /* Button styles */
  .buy-course-btn, .view-more-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .buy-course-btn:hover, .view-more-btn:hover {
    background-color: #0056b3;
  }
  
  /* View more section */
  .view-more-container {
    margin-top: 20px;
  }
  
  .view-more-btn {
    text-decoration: none;
    font-size: 1.2em;
  }
  
  /* Popup overlay */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Popup content */
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
  }
  
  .popup-title {
    font-size: 1.5em;
    /* margin-bottom: 20px; */
  }
  
  /* Form input styles */
  .form-input {
    width: calc(100% - 20px);
    margin: 10px 0;
    padding: 10px;
    /* border: 1px solid #ddd; */
    border-radius: 4px;
  }
  
  /* Button styles inside the popup */
  .submit-order-btn, .cancel-order-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .submit-order-btn:hover {
    background-color: #0056b3;
  }
  
  .cancel-order-btn {
    background-color: #dc3545;
  }
  
  .cancel-order-btn:hover {
    background-color: #c82333;
  }
  /* Disable scroll when popup is shown */
.no-scroll {
  overflow: hidden;
}

/* Popup styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent; /* Semi-transparent background */
  z-index: 1000; /* Ensure it's on top of all content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1010;
}

/* Ensure the body content doesn't overlap with the popup */
.submit-order-btn,
.cancel-order-btn {
  margin-top: 20px;
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.cancel-order-btn {
  background-color: red;
}
