.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  /* background: linear-gradient(180deg, #6C63FF 0%, #8A63FF 100%); */
}
