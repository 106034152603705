/* Sidebar.css */
.sidebar {
    width: 250px;
    height: 100vh;
    /* background: #c054ff; */
    background: linear-gradient(to bottom, #5274ff 50%, #c054ff 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px;
    transition: width 0.3s;
    overflow: hidden;
    position: fixed; /* Make sure the sidebar is fixed */
    top: 0;
    left: 0;
    z-index: 1000; /* Ensure sidebar is above other elements */
  }
  
  .sidebar.closed {
    width: 80px;
  }
  
  .sidebar-content {
    display: flex;
    flex-direction: column;
  }
  
  .sidebar-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  .sidebar-icon {
    font-size: 24px;
    margin-right: 10px;
    margin: 10px;
  }
  
  .sidebar-text {
    font-size: 18px;
    font-weight: 500;
  }
  
  .sidebar-item:hover {
    background: linear-gradient( to left, #5274ff 0%, #c054ff 100%);
    border-radius: 4px;
    /* padding: 10px  ; */
  }
  
  .toggle-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 20px;
  }
  